
import { ChannelsModule } from "@/store/modules/channels";
import { FriendsModule, FriendStatus } from "@/store/modules/friends";
import { MeModule } from "@/store/modules/me";
import { NotificationsModule } from "@/store/modules/notifications";
import { PopoutsModule } from "@/store/modules/popouts";
import { defineAsyncComponent } from "vue";
import { defineComponent } from "vue";
const FriendList = defineAsyncComponent(
  () => import("@/components/drawers/direct-message-drawer/FriendList.vue")
);
const RecentList = defineAsyncComponent(
  () => import("@/components/drawers/direct-message-drawer/RecentList.vue")
);

export default defineComponent({
  name: "MainApp",
  components: { FriendList, RecentList },
  data() {
    return {
      selectedTab: parseInt(localStorage.getItem("selectedDmTab") || "0"),
    };
  },
  computed: {
    friendRequestExists(): any {
      return this.friends.find((f) => f.status === FriendStatus.PENDING);
    },
    friends(): any {
      return FriendsModule.friendsWithUser;
    },
    dmNotificationExists(): any {
      return NotificationsModule.allDMNotifications.length > 0;
    },
    savedNotesSelected(): any {
      const channelId = this.$route.params.channel_id as string;
      if (!channelId) return false;
      const DMChannel = ChannelsModule.getDMChannel(channelId);
      const recipient = DMChannel?.recipients?.[0];
      return recipient?.id === MeModule.user.id;
    },
  },
  methods: {
    openSavedNotes() {
      if (!MeModule.user.id) return;
      ChannelsModule.LoadDmChannel(MeModule.user.id);
    },
    addFriendButton() {
      PopoutsModule.ShowPopout({
        component: "add-friend-popout",
        id: "add-friend",
      });
    },
    changeTab(index: number) {
      this.selectedTab = index;
      localStorage.setItem("selectedDmTab", index.toString());
    },
  },
});
